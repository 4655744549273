<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Título
                </th>
                <th v-if="isAutores" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Autores
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Área
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Modalidad
                </th>
                <th v-if="isButton" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Archivo
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="memoria in memorias" :key="memoria.titulo">
                <template v-if=" memoria.identificador === -1 ||  memoria.identificador === -2 ">
                  <td class="px-6 py-4 whitespace-nowrap" colspan="5">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{memoria.titulo}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap" >
                    <span class="px-3 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-red-100 text-theme-color p-1">
                        <ArrowCollapseDown :size="18" />
                      <a :href="memoria.archivo" target="_blank" class="pl-2"> 
                        Descargar</a>
                    </span>
                  </td>
                </template>

                <template v-else>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{memoria.identificador}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <p class="text-sm text-gray-900">{{memoria.titulo}}</p>
                  </td>
                  <td class="px-6 py-4" v-if="isAutores">
                    <p class="text-sm text-gray-500">{{memoria.autores}}</p>
                  </td>
                  <td class="px-6 py-4">
                    <p class="text-sm text-gray-900">{{memoria.area}}</p>
                  </td>
                  <td class="px-6 py-4">
                    <p class="text-sm text-gray-900">{{memoria.modalidad}}</p>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap" v-if="isButton">
                    <span class="px-3 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-red-100 text-theme-color p-1">
                        <ArrowCollapseDown :size="18" />
                      <a :href="memoria.archivo" target="_blank" class="pl-2"> 
                        Descargar</a>
                    </span>
                  </td>
                </template>


                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowCollapseDown from 'vue-material-design-icons/ArrowCollapseDown.vue';

export default {
  components: {
    ArrowCollapseDown
  },
  props: {
    memorias: {
      require: true
    },
    isButton: {
      type: Boolean,
      default: true
    },
    isAutores: {
      type: Boolean,
      default: false
    },
    isPaginaLegal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>